/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://vfu4lgib3zazdf5nxqinuizvrm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-jspjcdzzprafpeurg7nbrwd5pa",
    "aws_cognito_identity_pool_id": "us-east-2:b2e39cd8-ab44-40cf-9f15-e8ef2144f60f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_qAA4mx9lp",
    "aws_user_pools_web_client_id": "4pn3g1nmv8c2jhgp0fm9anfmid",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplifyappa8dc87b4adee4a23a4c590d03f3f853114540-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
