/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWeldingContent = /* GraphQL */ `
  query GetWeldingContent($id: ID!) {
    getWeldingContent(id: $id) {
      id
      image
      text
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeldingContents = /* GraphQL */ `
  query ListWeldingContents(
    $filter: ModelWeldingContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeldingContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        text
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      name
      email
      phone
      location
      state
      description
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        location
        state
        description
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
