import React, { useState, useEffect } from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
  Image,
} from "@aws-amplify/ui-react";
import { listNotes } from "../graphql/queries";
import {
  createNote as createNoteMutation,
  deleteNote as deleteNoteMutation,
} from "../graphql/mutations";
import { uploadData} from 'aws-amplify/storage';

import { generateClient } from 'aws-amplify/api';

const API = generateClient();

const Request = ({ signOut }) => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    fetchNotes();
  }, []);


  async function fetchNotes() {
    try {

      console.log('test');
      const apiData = await API.graphql({ query: listNotes });

      const notesFromAPI = apiData.data.listNotes.items;

      setNotes(notesFromAPI);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  }


  async function createNote(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const image = form.get("image");
    console.log('image from CreateNote: ' + image.name);
    const data = {
      name: form.get("name"),
      description: form.get("description"),
      image: image.name,
    };

    try {
      // Upload image if available
      if (image) {
        console.log(image);
        uploadData({
          key: image.name,
          data: image,
        });
      }
      console.log('data: ' + JSON.stringify(data));
      // Create note
      await API.graphql({
        query: createNoteMutation,
        variables: { input: data },
      });

      // Refresh notes
      fetchNotes();

      // Reset form
      event.target.reset();
    } catch (error) {
      console.error('Error creating note:', error);
    }
  }

  async function deleteNote({ id, name }) {
    try {
      const newNotes = notes.filter((note) => note.id !== id);
      setNotes(newNotes);

      //Not currently deleteing files https://docs.aws.amazon.com/AmazonS3/latest/userguide/example_s3_DeleteObject_section.html
      // Delete note
      await API.graphql({
        query: deleteNoteMutation,
        variables: { input: { id } },
      });
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  }


  return (
    <View className="App">
      <Heading level={1}>Request page</Heading>
      <View as="form" margin="3rem 0" onSubmit={createNote}>
        <Flex direction="row" justifyContent="center">
          <TextField
            name="name"
            placeholder="Note Name"
            label="Note Name"
            labelHidden
            variation="quiet"
            required
          />
          <TextField
            name="description"
            placeholder="Note Description"
            label="Note Description"
            labelHidden
            variation="quiet"
            required
          />
          <View
            name="image"
            as="input"
            type="file"
            style={{ alignSelf: "end" }}
          />
          <Button type="submit" variation="primary">
            Create Note
          </Button>
        </Flex>
      </View>
      <Heading level={2}>Current Notes</Heading>
      <View margin="3rem 0">
        {notes.map((note) => (
          <Flex
            key={note.id || note.name}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text as="strong" fontWeight={700}>
              {note.name}
            </Text>
            <Text>
              {note.email}
            </Text>
            <Text>
              {note.phone}
            </Text>
            <Text as="span">{note.description}</Text>
            {/* <p>{JSON.stringify(note)}</p> */}
            {note.image && (
              <Image
                src={`https://amplifyappa8dc87b4adee4a23a4c590d03f3f853114540-staging.s3.us-east-2.amazonaws.com/public/${note.image}`}
                alt={`visual aid for ${note.name}`}
                style={{ width: 400 }}
              />
            )}
            <Button variation="link" onClick={() => deleteNote(note)}>
              Delete note
            </Button>
          </Flex>
        ))}
      </View>
      <Button onClick={signOut}>Sign Out</Button>
      {/* <Home></Home> */}
    </View>
  );
};

export default withAuthenticator(Request);
// export default (Request);
