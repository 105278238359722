/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWeldingContent = /* GraphQL */ `
  mutation CreateWeldingContent(
    $input: CreateWeldingContentInput!
    $condition: ModelWeldingContentConditionInput
  ) {
    createWeldingContent(input: $input, condition: $condition) {
      id
      image
      text
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWeldingContent = /* GraphQL */ `
  mutation UpdateWeldingContent(
    $input: UpdateWeldingContentInput!
    $condition: ModelWeldingContentConditionInput
  ) {
    updateWeldingContent(input: $input, condition: $condition) {
      id
      image
      text
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWeldingContent = /* GraphQL */ `
  mutation DeleteWeldingContent(
    $input: DeleteWeldingContentInput!
    $condition: ModelWeldingContentConditionInput
  ) {
    deleteWeldingContent(input: $input, condition: $condition) {
      id
      image
      text
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      name
      email
      phone
      location
      state
      description
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      name
      email
      phone
      location
      state
      description
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      name
      email
      phone
      location
      state
      description
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
