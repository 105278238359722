import React from "react";
import { View, Image } from "@aws-amplify/ui-react";
import { BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Request from './pages/Request';
import CreateRequest from "./pages/CreateRequest";
import RequestList from "./pages/RequestList";
import RequestForm from "./pages/RequestForm";
import Contact from "./pages/Contact";
import LoginPage from "./pages/Login";
import SunshineLogo from "./assets/sunshine_welding.png";
import { getCurrentUser } from 'aws-amplify/auth';
import "./css/app.css";

function App(){

 const [isAuthenticated, setIsAuthenticated] = React.useState(false); // State to manage authentication

  React.useEffect(() => {
    checkAuthStatus(); // Check authentication status when component mounts
  }, []);

  const checkAuthStatus = async () => {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    console.log(`The username: ${username}`);
    console.log(`The userId: ${userId}`);
    console.log(`The signInDetails: ${signInDetails}`);
    setIsAuthenticated(true);
  } catch (err) {
    console.log(err);
  }
  };


  return (
    <View className="App">
      <Router>
        <nav className="navbar">
          <div className="left-section">
            <Link id="logo-link" to="/"><Image id="logo" src={SunshineLogo} alt="Sunshine Welding Logo" /></Link>
          </div>
          
          <div className="right-section">
            <ul className="nav-links">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/create-request">Create Request</Link></li>
              <li><Link to="/contact">Info</Link></li>
              {isAuthenticated && 
                <li><Link to="/request-list">List</Link></li>
              }

            </ul>
          </div>
        </nav>
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/create-request" element={<CreateRequest/>} />
            <Route path="/request" element={<Request />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<LoginPage/>}></Route>
            <Route path="/request-list" element={<RequestList />} />
            <Route path="/request-form/:requestId" element={<RequestForm />} />
          </Routes>
        </div>
      </Router>
    </View>
  );
};

export default App;
