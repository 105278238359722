// version five
import React, { useState, useEffect } from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import { Button, View } from "@aws-amplify/ui-react";
import { getCurrentUser } from 'aws-amplify/auth';
import { listWeldingContents } from "../graphql/queries";
import {
  deleteWeldingContent as deleteNoteMutation,
  updateWeldingContent as updateNoteMutation
} from "../graphql/mutations";
import { generateClient } from 'aws-amplify/api';
import '../css/PhotoGrid.css';

const API = generateClient();

function PhotoGrid({ editMode }) {
  const [notes, setNotes] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [editData, setEditData] = useState({ text: '', order: '' });
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchNotes();
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      console.log(`The username: ${username}`);
      console.log(`The userId: ${userId}`);
      console.log(`The signInDetails: ${signInDetails}`);
      setIsAuthenticated(true);
    } catch (err) {
      console.log(err);
    }
  };

  async function fetchNotes() {
    try {
      const apiData = await API.graphql({ query: listWeldingContents });
      const notesFromAPI = apiData.data.listWeldingContents.items;
      const sortedNotes = notesFromAPI.sort((a, b) => a.order - b.order);
      setNotes(sortedNotes);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  }

  async function deleteNote({ id }) {
    try {
      console.log('Attempting to delete note with id:', id);
      const newNotes = notes.filter((note) => note.id !== id);
      setNotes(newNotes);
      await API.graphql({
        query: deleteNoteMutation,
        variables: { input: { id } },
      });
      console.log(`Note with id ${id} deleted successfully.`);
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  }

  const startEditing = (note) => {
    setEditingId(note.id);
    setEditData({ text: note.text, order: note.order });
  };

  const handleUpdateClick = async (noteToUpdate) => {
    try {
      const updatedNote = {
        id: noteToUpdate.id,
        text: editData.text,
        order: parseInt(editData.order),
        image: noteToUpdate.image, // Retain the original image URL
      };

      // Perform the GraphQL mutation to update the note in the database
      await API.graphql({
        query: updateNoteMutation,
        variables: { input: updatedNote },
      });

      // Update the local state with the updated note
      const updatedNotes = notes.map((note) =>
        note.id === noteToUpdate.id ? updatedNote : note
      );
      setNotes(updatedNotes);

      // Sort the updatedNotes array based on the order field
      const sortedNotes = updatedNotes.sort((a, b) => a.order - b.order);
      setNotes(sortedNotes);

      console.log(`Note with id ${noteToUpdate.id} updated successfully.`);
      setEditingId(null); // Clear editing state after successful update
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  return (
    <View className="App">
      <div className="grid-container">
        {notes.map((note, index) => (
          <div className="grid-item" key={index}>
            <img src={`https://amplifyappa8dc87b4adee4a23a4c590d03f3f853114540-staging.s3.us-east-2.amazonaws.com/public/WeldingContent/${note.image}`} alt={note.image} />
           
                  <p>{note.text}</p>
            <div id="card-lower">

            {/* If possible, make one note.text input that is read only when not in edit mode. Should have border when editing, no border when viewing */}
            {/* Need to remove empty alternative condition in the code block below */}

              {editMode && editingId === note.id ? (
                <div>
                  <input
                    className="text-input"
                    type="text"
                    value={editData.text}
                    onChange={(e) => setEditData({ ...editData, text: e.target.value })}
                  />
                  <input
                    className="text-input"
                    type="number"
                    value={editData.order}
                    onChange={(e) => setEditData({ ...editData, order: e.target.value })}
                  />
                  <Button onClick={() => handleUpdateClick(note)}>Update</Button>
                </div>
              ) : (
                <>
                  {/* <p>{note.text}</p> */}
                </>
              )}
              {isAuthenticated && (
                <>
                  {editMode && editingId !== note.id && (
                    <div id="card-lower">
                      <p> {note.order} </p>
                      <Button onClick={() => startEditing(note)}>Edit</Button>
                      <Button id="delete-button" variation="link" onClick={() => deleteNote(note)}>
                        &#128465;
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </View>
  );
}

export default PhotoGrid;
