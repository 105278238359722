import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  View,
  withAuthenticator
} from "@aws-amplify/ui-react";
import { getNote } from "../graphql/queries";
import { updateNote as updateNoteMutation } from "../graphql/mutations";
import { generateClient } from 'aws-amplify/api';
import '../css/request-form.css';
const API = generateClient();

const RequestForm = ({ signOut }) => {
  const { requestId } = useParams();
  const [request, setRequest] = useState({});

  useEffect(() => {
    const getNoteById = async () => {
      try {
        const request = await API.graphql({
          query: getNote,
          variables: { id: requestId }
        });

        const note = request.data.getNote;
        setRequest(note);
        // console.log('Note:', note);
      } catch (error) {
        console.error('Error fetching note:', error);
      }
    };

    getNoteById();

  }, [requestId]);

  async function updateNote(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      id: requestId,
      name: form.get("name"),
      email: form.get("email"),
      phone: form.get("phone"),
      location: form.get("location"),
      state: form.get("state"),
      description: form.get("description"),
    };

    try {
      await API.graphql({
        query: updateNoteMutation,
        variables: { input: data },
      });
      alert('Record Updated');
    } catch (error) {
      console.error('Error creating note:', error);
    }
  }

  return (
    <div>
      <View as="form" id="view" onSubmit={updateNote}>
        <div className="simple-form-container">
          <div className="simple-form">

            <label className='request-form-label' htmlFor="name">Name</label>
            <input
              name="name"
              placeholder="Name"
              defaultValue={request.name || ''}
            />
            {/* Consider putting email & phone number on the same line side by side */}
            <label className='request-form-label' htmlFor="email">Email</label>
            <input
              name="email"
              placeholder="Email"
              defaultValue={request.email || ''}
            />
            <label className='request-form-label' htmlFor="phone">Phone</label>
            <input
              name="phone"
              placeholder="Phone"
              defaultValue={request.phone || ''}
            />
            <label className='request-form-label' htmlFor="location">Location</label>
            <input
              name="location"
              placeholder="Location"
              defaultValue={request.location || ''}
            />
            <label className='request-form-label' htmlFor="state">State</label>
            <div className="form-group">
              <select id="state" name="state" value={request.state} onChange={(e) => setRequest({ ...request, state: e.target.value })}>
                <option value="" >Select a state</option>
                <option value="New">New</option>
                <option value="In-Progress">In Progress</option>
                <option value="Done">Done</option>
              </select>
            </div>
            <label className='request-form-label' htmlFor="description">Description</label>
            <textarea
              name="description"
              placeholder="Description"
              defaultValue={request.description || ''}
            />

            <label className='request-form-label' htmlFor="created">Created</label>
            <input
              name="created"
              placeholder="Created"
              value={request.createdAt?.substring(0, 10) || ''}
              style={{ cursor: 'not-allowed' }}
              readOnly
            />

            {request.image && (
              <div>
                <img
                  src={`https://amplifyappa8dc87b4adee4a23a4c590d03f3f853114540-staging.s3.us-east-2.amazonaws.com/public/${request.image}`}
                  alt={`visual aid for ${request.name}`}
                  style={{ width: 500 }}
                  id='request-form-img'
                />
              </div>
            )}
            <Button type='subimt' variation="primary" >
              Update
            </Button>
          </div>
        </div>

      </View>
      <Button id='sign-out-button' onClick={signOut}>Sign Out</Button>
    </div>
  );


}

export default withAuthenticator(RequestForm);