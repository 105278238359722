import React,{useEffect} from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';

/* This page is used to allow the user to easily put into the url /login, provide withAuthenticator functionlaity, then redirect to request-list page */

function Login() {
  useEffect(() => {
          window.location.href = '/request-list';
  }, []);
  return <h1>Login</h1>;
}

export default withAuthenticator(Login);