// import React from 'react';
// // import weldingImage from "../assets/welding-image.jpg";
// // import weldingImage from "../assets/1-table.JPG";
// // import weldingImage from "../assets/2-gaming-chair.JPG";
// // import weldingImage from "../assets/3-wall.jpg";
// // import weldingImage from "../assets/4-mc-ds-fence-action.jpg";
// // import weldingImage from "../assets/5-mc-ds-fence-cut.jpg";
// // import weldingImage from "../assets/6-ground-action.JPG";
// // import weldingImage from "../assets/6-ground-action-v2.JPG";

//  import weldingImage from "../assets/6-ground-action-v4.jpg";
//  import PhotoGrid from "../components/PhotoGrid";

// import '../css/home.css'
// function Home() {
//   return (
//     <div id='home-container'>
//       <div><img src={weldingImage} alt="Welding Business" id='home-img' style={{ maxWidth: '100%', height: 'auto' }} /></div>
//       <div className='home-text-container'>
//         <p className='home-text'>With over seven years experience, Sunshine’s welding is a newly established welding workshop dedicated to providing personalized metal fabrication and repair services. Our seasoned craftsmen specialize in working with various metals, including steel, aluminum, and stainless steel, ensuring meticulous attention to detail and high-quality results.</p>
//         <p className='home-text'> We pride ourselves on delivering professional expertise and customer-centric service, tailored to meet the unique needs of each client. Whether it's a custom project or routine maintenance, Sunshine’s is your reliable partner for all your welding requirements</p>
//       </div>
//       <PhotoGrid />
//     </div>
//   );
// }

// export default Home;



// import React, { useState, useEffect } from 'react';
// import weldingImage from "../assets/6-ground-action-v4.jpg";
// import PhotoGrid from "../components/PhotoGrid";
// import SubmitData from "../components/CreateWeldingContent";

// import { getCurrentUser } from 'aws-amplify/auth';
// import '../css/home.css';

// function Home() {

//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     checkAuthStatus();
//   }, []);

//   const checkAuthStatus = async () => {
//     try {
//       const { username, userId, signInDetails } = await getCurrentUser();
//       console.log(`The username: ${username}`);
//       console.log(`The userId: ${userId}`);
//       console.log(`The signInDetails: ${signInDetails}`);
//       setIsAuthenticated(true);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   return (
//     <div>
//       <div id='home-container'>
//         <img src={weldingImage} alt="Welding Business" id='home-img' />
//         <div className='home-text-container'>
//           <p className='home-text'>With over seven years experience, Sunshine’s welding is a newly established welding workshop dedicated to providing personalized metal fabrication and repair services. Our seasoned craftsmen specialize in working with various metals, including steel, aluminum, and stainless steel, ensuring meticulous attention to detail and high-quality results.</p>
//           <p className='home-text'> We pride ourselves on delivering professional expertise and customer-centric service, tailored to meet the unique needs of each client. Whether it's a custom project or routine maintenance, Sunshine’s is your reliable partner for all your welding requirements.</p>
//         </div>
//       </div>
//       <PhotoGrid />
//       {isAuthenticated && (
//         <SubmitData />
//       )}
//     </div>
//   );
// }

// export default Home;



import React, { useState, useEffect } from 'react';
import weldingImage from "../assets/6-ground-action-v4.jpg";
import PhotoGrid from "../components/PhotoGrid";
import SubmitData from "../components/CreateWeldingContent";
import { getCurrentUser } from 'aws-amplify/auth';
import '../css/home.css';

function Home() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    checkAuthStatus();
    checkDeviceType();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      console.log(`The username: ${username}`);
      console.log(`The userId: ${userId}`);
      console.log(`The signInDetails: ${signInDetails}`);
      setIsAuthenticated(true);
    } catch (err) {
      console.log(err);
    }
  };

 const checkDeviceType = () => {
    const isMobileDevice = window.matchMedia('(max-width: 768px)').matches;
    setIsMobile(isMobileDevice);
  };

  const toggleEditMode = () => {
    setEditMode((prevMode) => !prevMode); // Step 2: Create toggle function
  };

  return (
    <div>
      <div id='home-container'>
        <img src={weldingImage} alt="Welding Business" id='home-img' />
        <div className='home-text-container'>
          <p className='home-text'>With over seven years experience, Sunshine’s welding is a newly established welding workshop dedicated to providing personalized metal fabrication and repair services. Our seasoned craftsmen specialize in working with various metals, including steel, aluminum, and stainless steel, ensuring meticulous attention to detail and high-quality results.</p>
          <p className='home-text'> We pride ourselves on delivering professional expertise and customer-centric service, tailored to meet the unique needs of each client. Whether it's a custom project or routine maintenance, Sunshine’s is your reliable partner for all your welding requirements.</p>
        </div>
      </div>
      {/* Keeping for now, remove if this is not needed along with the supporting variables & functions */}
      {/* {!isMobile && <PhotoGrid editMode={editMode} />}
      {isAuthenticated && !isMobile && ( */}

      <PhotoGrid editMode={editMode} />
      {isAuthenticated && (
        <div>
          {editMode && <SubmitData/>}
          <button id='edit-toggle-button' onClick={toggleEditMode}>
            {editMode ? "Switch to View Mode" : "Switch to Edit Mode"}
          </button>
        </div>
      )}
    </div>
  );
}

export default Home;

