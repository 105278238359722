import React from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import {
  Button,
  Heading,
  View,
} from "@aws-amplify/ui-react";
import {
  createNote as createNoteMutation,
  deleteNote as deleteNoteMutation,
} from "../graphql/mutations";
import { uploadData } from 'aws-amplify/storage';
import { generateClient } from 'aws-amplify/api';
import "../css/create-request.css";

const API = generateClient();

function CreateRequest() {


  async function createNote(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const image = form.get("image");
    console.log('image from CreateNote: ' + image.name);
    const data = {
      name: form.get("name"),
      email: form.get("email"),
      phone: form.get("phone"),
      location: form.get("location"),
      state: "New",
      description: form.get("description"),
      image: image.name,
    };

    try {
      // Upload image if available
      if (image) {
        try {
          uploadData({
            key: image.name,
            data: image,
          });
        } catch (uploadError) {
          console.error('Error uploading image: ', uploadError);
        }
      }
      // Create note
      await API.graphql({
        query: createNoteMutation,
        variables: { input: data },
      });

      event.target.reset();
    } catch (error) {
      console.error('Error creating note:', error);
    }
  }

  return (
    <View className="App">
      <Heading level={3}>Create Request</Heading>
      <View as="form" id="view" onSubmit={createNote}>
        <div className="simple-form-container">
          <div className="simple-form">
            <input
              name="name"
              placeholder="Name"
              label="Name"
              labelHidden
              variation="quiet"
              required
            />
            {/* Consider putting email & phone number on the same line side by side */}
            <input
              name="email"
              placeholder="Email"
              label="Email"
              labelHidden
              variation="quiet"
              required
            />
            <input
              name="phone"
              placeholder="Phone"
              label="Phone"
              labelHidden
              variation="quiet"
              required
            />

            <input
              name="location"
              placeholder="Location"
              label="Location"
              labelHidden
              variation="quiet"
              required
            />
            <textarea
              name="description"
              placeholder="Description"
              label="Description"
              labelHidden
              variation="quiet"
              required
            />
            <View
              name="image"
              id="input-image"
              as="input"
              type="file"
            />
            <Button type="submit" variation="primary" >
              Submit
            </Button>
          </div>
        </div>

      </View>
    </View>
  );
};

export default CreateRequest;