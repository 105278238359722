import React, { useState, useEffect } from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import {
  Button,
  Heading,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { listNotes } from "../graphql/queries";
import {
  createNote as createNoteMutation,
  deleteNote as deleteNoteMutation,
} from "../graphql/mutations";
import { generateClient } from 'aws-amplify/api';
import '../css/request-list.css'

const API = generateClient();

const RequestList = ({ signOut }) => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    fetchNotes();
  }, []);

  async function fetchNotes() {
  try {
    const apiData = await API.graphql({ query: listNotes });
    const notesFromAPI = apiData.data.listNotes.items;
    
    // Sort notes by most recent
    const sortedNotes = notesFromAPI.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    
    setNotes(sortedNotes);
  } catch (error) {
    console.error('Error fetching notes:', error);
  }
}


  async function deleteNote({ id }) {
    try {
      const newNotes = notes.filter((note) => note.id !== id);
      setNotes(newNotes);

      // Delete note
      await API.graphql({
        query: deleteNoteMutation,
        variables: { input: { id } },
      });
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  }

  return (
    <View className="App" >
      <Heading level={1}>Request list</Heading>
      {notes && notes.length > 0 ? (

      <table id="customers">
        <thead>
          <tr>
            <th>Name</th>
            <th>State</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Description</th>
            <th>Created</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {notes.map((note) => (
            <tr key={note.id || note.name}>
              <td><a href={`/request-form/${note.id}`}>{note.name}</a></td>
              <td>{note.state}</td>
              <td>{note.email}</td>
              <td>{note.phone}</td>
              <td>{note.description}</td>
              <td>{note.createdAt.substring(0, 10)}</td>
              <td>
                <Button id="delete-button" variation="link" onClick={() => deleteNote(note)}>
                  &#128465;
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      ) : (
        <p>No requests available</p>
      )}
      <Button id='sign-out-button' onClick={signOut}>Sign Out</Button>
    </View>
  );
};

export default withAuthenticator(RequestList);
