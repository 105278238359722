import React from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import {
  Button,
  Heading,
  View,
  withAuthenticator
} from "@aws-amplify/ui-react";
import {
  createWeldingContent as createWeldingContentMutation,
} from "../graphql/mutations";
import { uploadData } from 'aws-amplify/storage';


import { generateClient } from 'aws-amplify/api';

const API = generateClient();

function CreateWeldingContent() {
  async function createWeldingContent(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const image = form.get("image");
    console.log('Image from CreateWeldingContent: ' + image.name);
    const data = {
      image: image.name,
      text: form.get("text"),
      order: parseFloat(form.get("order")),
    };

    try {
      if (image) {
        try {
          uploadData({
            key: 'WeldingContent/'+image.name,
            data: image,
          });
        } catch (uploadError) {
          console.error('Error uploading image: ', uploadError);
        }
      }
      // Create welding content
      await API.graphql({
        query: createWeldingContentMutation,
        variables: { input: data },
      });

      event.target.reset();
    } catch (error) {
      console.error('Error creating welding content:', error);
    }
  }

  return (
    <View className="App">
      <Heading level={3}>Create Welding Content</Heading>
      <View as="form" id="view" onSubmit={createWeldingContent}>
        <div className="simple-form-container">
          <div className="simple-form">
            <input
              name="text"
              placeholder="Text"
              label="Text"
              labelHidden
              variation="quiet"
              required
            />
            <input
              name="order"
              placeholder="Order"
              label="Order"
              labelHidden
              type="number"
              step="0.1"
              variation="quiet"
              required
            />
            <View
              name="image"
              id="input-image"
              as="input"
              type="file"
            />
            <Button type="submit" variation="primary">
              Submit
            </Button>
          </div>
        </div>
      </View>
    </View>
  );
};

export default withAuthenticator(CreateWeldingContent);
